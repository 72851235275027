.contact-form-wrapper {
  padding: 20px;
}

.contact-form-title {
  font-size: 22px;
  font-weight: 500;
}

.text-area {
  resize: none;
}

.form-element-wrapper {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.input-area {
  border-radius: 3px;
  line-height: 25px;
  padding: auto 5px;
  border: 1px black solid;
  margin-top: 5px;
}

.btn-submit {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

@media (min-width: 778px) {
  .btn-submit {
    display: flex;
    padding-left: 10px;
    padding-top: 10px;
    justify-content: flex-start;
    margin: 0;
  }
}

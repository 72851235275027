.contact-main-wrapper {
  display: flex;
  flex-direction: column;
}

.image-style {
  object-fit: cover;
  height: 10vh;
}

.contact-content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 40px;
  max-height: 650px;
}

.contact-content-wrapper > * {
  width: 100%;
}

.contact-form-img {
  display: none;
}

@media (min-width: 778px) {
  .contact-content-wrapper > * {
    width: 40%;
    max-height: 600px;
  }

  .image-style {
    height: 15vh !important;
  }
  .contact-form-img {
    display: block;
    border-radius: 30px;
    object-fit: cover;
    height: inherit;
  }
}

.single-brand-wrapper {
  margin: 5% 0;
  width: 100%;
}

.single-brand-img {
  width: 100%;
}

.brand-information-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.brand-information-wrapper > * {
  margin: 10px 0;
}

.brand-btn {
  margin-left: 12px;
}

.single-brand-text {
  font-size: 18px;
  font-weight: 600;
}

@media (min-width: 778px) {
  .single-brand-wrapper {
    width: 45%;
    margin: 0 2.5% 2.5% 2.5%;
  }
}

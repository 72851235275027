.message-card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  width: 300px;
  height: 300px;
  border-radius: 30px;
}

.msg-info-wrapper {
  width: inherit;
  padding-top: 20px;
  padding-left: 20px;
}

.msg-title {
  font-size: 40px;
  font-weight: 500;
}

.msg-msg {
  width: 280px;
  font-size: 25px;
  word-wrap: break-word;
}

.msg-btn {
  width: inherit;
  padding-bottom: 20px;
  padding-left: 45px;
}

@media screen and (min-width: 778px) {
  .message-card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
    width: 300px;
    height: 300px;
    border-radius: 30px;
  }

  .msg-info-wrapper {
    width: inherit;
    padding-top: 20px;
    padding-left: 20px;
  }

  .msg-title {
    font-size: 40px;
    font-weight: 500;
  }

  .msg-msg {
    width: 280px;
    font-size: 25px;
    word-wrap: break-word;
  }

  .msg-btn {
    width: inherit;
    padding-bottom: 20px;
    padding-left: 45px;
  }
}
